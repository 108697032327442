import festival1 from "../images/Schermafbeelding092826.png";
import festival2 from "../images/Schermafbeelding093021.png";
import lekkerVanBijOns from "../images/Schermafbeelding104132.png";

export default function Home() {
  return (
    <div>
      <div className="homeParallax">
        <p className="parallaxText">Hallo ik ben Sacha</p>
        <p className="parallaxSubText">Let niet op dit portfolio</p>
        <p className="parallaxSubText">Degelijke portfolio coming soon...</p>
      </div>

      <div className="work">
        <img className="small" src={festival1} alt="" />
        <img className="small" src={festival2} alt="" />
        <div className="small">
          <h1>Mobile festival app (Design)</h1>
          <p>
            (XD) Een projectje voor design waar we een mobiel design hebben moeten maken voor een festival.
            Ik ben gegaan voor een muziekfestival waar ik heel graag naartoe zou gaan als het uitgevoerd zou worden.
          </p>
          <a target="_blank" href="https://xd.adobe.com/view/3a317c08-a178-4cf6-ba27-6658e94dd57f-6f62/screen/ecf60fbc-9b37-401b-8205-5e91c371f03b/?fullscreen">Check it out</a>
        </div>
      </div>
      <div className="work">
        <div className="small">
          <h1>Lekker van bij ons revamped
            (Front-end)
          </h1>
          <p>(React en Angular) Een project voor Front-end waar we een site moesten redesignen en coderen.
          </p>
          <a target="_blank" href="https://sacha-react.netlify.app/">Check it out</a>
        </div>
        <img className="wide" src={lekkerVanBijOns} alt="" />
      </div>
    </div>
  );
}