import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

// Components
import Navigation from './components/Navigation';
import Footer from "./components/Footer";
import './App.scss';

import Home from './components/Home';
import About from "./components/About";


function App() {
  return (
    <BrowserRouter>
      <div className="pageContent">
        <div>
          <Navigation />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
